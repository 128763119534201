(function () {

    const stickyBar = document.querySelector(".sticky-bar");
    if (!stickyBar) return;

    const stickyBarTrigger = document.querySelector("#" + stickyBar.dataset.stickyBarTrigger);
    if (!stickyBarTrigger) return;

    if ("IntersectionObserver" in window) {
        let observer = new IntersectionObserver(function(entries) {
            if (entries[0].boundingClientRect.y < 0) {
                stickyBar.classList.add("sticky-bar--active");
                document.body.style.marginBottom = (stickyBar.offsetHeight + 10) + 'px';
            }
        });

        observer.observe(stickyBarTrigger);
    }
})();
